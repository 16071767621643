











































































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, onMounted } from "@vue/composition-api";
import {
  OrgCollapseGroup,
  AtomSwitcher,
  AtomTextTypeEnum,
  AtomIcon,
  AtomButton,
  AtomSwitcherSizeEnum,
  AtomButtonTypeEnum,
} from "@/v2/new-design-system";
import { voidReasonRepo } from "../../repo";
import { VoidReasonReasonForm } from "../../component/reason-form";
import { VoidReasonCategoryForm } from "../../component/category-form";
import { t } from "@/i18n";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { report } from "@chatfood/bug-reporter";
import { ICategoryWithReasons } from "@/module/void-reason/domain/category";
import { Toast } from "@/design-system";

const css = bemBuilder("void-reason-main");

export default defineComponent({
  name: "VoidReasonMain",
  components: {
    OrgCollapseGroup,
    AtomSwitcher,
    AtomIcon,
    AtomButton,
    VoidReasonCategoryForm,
    VoidReasonReasonForm,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isCategoryVisible = ref(false);
    const allVoidCategories = ref<ICategoryWithReasons[]>([]);

    async function getCategories() {
      try {
        allVoidCategories.value = await voidReasonRepo.getCategories({
          businessId: props.businessId,
        });
      } catch (e) {
        report(e);
        generalErrorToast();
      }
    }

    const showCategoryModal = ref(false);
    const categoryToBeUpdated = ref(undefined);
    function openCategoryModal(category?: any) {
      if (category) {
        categoryToBeUpdated.value = category;
      }
      showCategoryModal.value = true;
    }

    function closeCategoryModal() {
      categoryToBeUpdated.value = undefined;
      showCategoryModal.value = false;
    }

    const showReasonModal = ref(false);
    const categoryIdSelected = ref("");
    const reasonToBeUpdated = ref(undefined);

    function openReasonModal(categoryId: string, reason?: any) {
      categoryIdSelected.value = categoryId;
      if (reason) {
        reasonToBeUpdated.value = reason;
      }
      showReasonModal.value = true;
    }

    function closeReasonModal() {
      categoryIdSelected.value = "";
      reasonToBeUpdated.value = undefined;
      showReasonModal.value = false;
    }

    function showVisibleIcon(visibleIcon: boolean) {
      return visibleIcon ? "eye" : "eye-slash";
    }

    async function updateReasonStatus(
      reasonId: string,
      categoryId: string,
      newState: boolean
    ) {
      const reason = allVoidCategories.value
        .find((c) => c.id === categoryId)
        ?.reasons.find((r) => r.id === reasonId);

      if (!reason) {
        throw new Error("Update status for undefined reason");
      }

      reason.isEnabled = newState;

      try {
        await voidReasonRepo.updateReasonStatus({
          businessId: props.businessId,
          id: reasonId,
          isEnabled: newState,
          voidCategoryId: categoryId,
        });

        new Toast().create({
          type: "success",
          text: newState
            ? t("module.void_reason.main.reason_enabled_message", {
                reason: reason.name,
              })
            : t("module.void_reason.main.reason_disabled_message", {
                reason: reason.name,
              }),
        });
      } catch (e) {
        reason.isEnabled = !newState;
        report(e);
        generalErrorToast();
      }
    }

    const whenCategorySaved = async () => {
      await getCategories();
      closeCategoryModal();
    };

    const whenReasonSaved = async () => {
      await getCategories();
      closeReasonModal();
    };

    onMounted(() => {
      getCategories();
    });

    return {
      t,
      css,
      AtomTextTypeEnum,
      AtomButtonTypeEnum,
      allVoidCategories,
      isCategoryVisible,
      updateReasonStatus,
      AtomSwitcherSizeEnum,
      showCategoryModal,
      openReasonModal,
      categoryIdSelected,
      showReasonModal,
      closeReasonModal,
      reasonToBeUpdated,
      closeCategoryModal,
      openCategoryModal,
      categoryToBeUpdated,
      showVisibleIcon,
      whenCategorySaved,
      whenReasonSaved,
    };
  },
});
