

























































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, PropType, watch } from "@vue/composition-api";
import { voidReasonRepo } from "../../repo";
import {
  AtomModal,
  AtomText,
  AtomTextTypeEnum,
  AtomInput,
  MolFormControl,
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
} from "@/v2/new-design-system";
import { Toast } from "@/design-system";
import { t } from "@/i18n";
import { generalErrorToast } from "@/v2/util/general-error-toast";
import { report } from "@chatfood/bug-reporter";
import { IReason } from "@/module/void-reason/domain/reason";

const css = bemBuilder("void-reason-reason-form");

export default defineComponent({
  name: "VoidReasonReasonForm",
  components: {
    AtomModal,
    AtomText,
    AtomInput,
    MolFormControl,
    AtomButton,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    voidCategoryId: {
      type: String,
      required: true,
    },
    reason: {
      type: Object as PropType<IReason>,
      default: undefined,
    },
  },
  emits: {
    close: () => true,
    save: () => true,
  },
  setup(props, { emit }) {
    const reasonName = ref("");
    const isLoading = ref(false);

    function onClose() {
      emit("close");
    }

    async function addReason() {
      isLoading.value = true;
      try {
        const params = {
          businessId: props.businessId,
          voidCategoryId: props.voidCategoryId,
          reason: reasonName.value,
        };

        !props.reason
          ? await voidReasonRepo.createReason(params)
          : await voidReasonRepo.updateReason({
              ...params,
              id: props.reason.id,
            });

        new Toast().create({
          type: "success",
          text: t("module.void_reason.reason_form.toast_success_message"),
        });

        emit("save");
      } catch (e) {
        report(e);
        generalErrorToast();
      } finally {
        isLoading.value = false;
      }
    }

    watch(
      () => props.reason,
      (val) => {
        if (val) {
          reasonName.value = val.name;
        }
      },
      { immediate: true }
    );

    return {
      t,
      css,
      onClose,
      AtomTextTypeEnum,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
      addReason,
      reasonName,
      isLoading,
    };
  },
});
