




































































import { bemBuilder } from "@/v2/util/bem-builder";
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
} from "@vue/composition-api";
import {
  AtomModal,
  AtomText,
  AtomTextTypeEnum,
  AtomInput,
  MolFormControl,
  AtomButton,
  AtomButtonSizeEnum,
  AtomButtonTypeEnum,
  AtomCheckbox,
} from "@/v2/new-design-system";
import { Toast } from "@/design-system";
import { t } from "@/i18n";
import { voidReasonRepo } from "../../repo";
import { generalErrorToast } from "@/v2/util/general-error-toast";

const css = bemBuilder("void-reason-category-form");

type IResource = {
  name: string;
  id: string;
  isVisibleToCustomer: boolean;
};

export default defineComponent({
  name: "VoidReasonCategoryForm",
  components: {
    AtomModal,
    AtomText,
    AtomInput,
    MolFormControl,
    AtomButton,
    AtomCheckbox,
  },
  props: {
    businessId: {
      type: String,
      required: true,
    },
    resource: {
      type: Object as PropType<IResource>,
      default: undefined,
    },
  },
  emits: {
    close: () => true,
    save: () => true,
  },
  setup(props, { emit }) {
    const isVisible = ref(false);
    const categoryName = ref("");
    const isLoading = ref(false);

    watch(
      () => props.resource,
      (val) => {
        if (val) {
          categoryName.value = val.name;
          isVisible.value = val.isVisibleToCustomer;
        }
      },
      { immediate: true }
    );

    const isEditing = computed(() => props.resource);

    async function addVoidCategory() {
      isLoading.value = true;

      try {
        const payload = {
          businessId: props.businessId,
          name: categoryName.value,
          isVisibleToCustomer: isVisible.value,
        };

        isEditing.value
          ? await voidReasonRepo.updateCategory({
              voidCategoryId: props.resource.id,
              ...payload,
            })
          : await voidReasonRepo.createCategory(payload);

        new Toast().create({
          type: "success",
          text: t("module.void_reason.category_form.toast_success_message"),
        });
        emit("save");
      } catch (e) {
        generalErrorToast();
      } finally {
        isLoading.value = false;
        onClose();
      }
    }

    function onClose() {
      emit("close");
    }

    return {
      t,
      css,
      onClose,
      AtomTextTypeEnum,
      AtomButtonSizeEnum,
      AtomButtonTypeEnum,
      isVisible,
      categoryName,
      addVoidCategory,
      isLoading,
      isEditing,
    };
  },
});
